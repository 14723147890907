.mainOuterOfferDiv {
  background-color: #0154AE;
  padding: 20px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  margin-top: 120px;

}

.innerMainOfferDiv {
  text-align: center;
}

.form_header {
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 10px;
  

}

.offerFormInput {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  height: 30px;
  font-size: 14px;
}

.offerFormbutton {
  background-color: orange;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  height: 30px;
  width: 100%;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 10px;
}

.offerFormbutton:hover {
  background-color: orange;
  color: #ffffff;
}
.offerFormInput::placeholder{
font-size: 12px;
}




@media only screen and (min-width: 200px) and (max-width: 767px) {
    .mainOuterOfferDiv{
        margin-bottom: 50px;
        margin-top: 0px;
    }
    
  }
  
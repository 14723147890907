.get-in-touch-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1% 10% !important;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-width: 100%;
  margin: 0 auto 100px auto;
}

.headingGetInTouchMainDiv {
  text-align: center;
  align-items: center;
}
.mapContainer {
  margin-top: 130px;
}

.adsbfrmcon {
  background-color: white;
  margin-bottom: 0px !important;
  padding: 5% 5% !important;
}
.title_git {
  margin-top: 0px;
  font-size: 36px;
  font-weight: 600;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
}

.headingGetInTouchMainDiv p{
  margin-bottom: 60px;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 400;
}

.form-container_git {
  width: 100%;
}

.form-label_git {
  display: block;
  margin: 10px 0;
  font-weight: bold;
}

.form-input_git {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 5px;
}

.form-textarea_git {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit-button_git {
  display: block;
  background-color: #0154ae;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  width: 100%;
}

@media (max-width: 768px) {
  .get-in-touch-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1% !important;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-width: 100%;
    margin: 0 auto 100px auto;
  }
  .mapContainer {
    margin-top: 80px;
  }
  .mobSpan{
    display: none;
  }
}

.getintouchimagecontainer_main {
  margin-top: 100px;
}

.contactImages {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

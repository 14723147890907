.threeSliderMian{
    padding: 3% !important;
    background-color: white !important;
  }


  @media (max-width: 768px) {
    .threeSliderMian{
        padding: 3% !important;
      }
  }
  
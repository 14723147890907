.threeSliderMain {
    padding:1% 3% !important;
    background-color: white !important;
      margin-top: 85px;
  }
  
  @media (max-width: 768px) {
    .threeSliderMain {
      padding: 3% !important;
      margin-top: 50px;
    }
  
  }
  
  .offerSLiderMain {
    margin-bottom: 50px;
  }
  
  @media only screen and (min-width: 200px) and (max-width: 767px) {
    .offerSLiderMain {
      margin-bottom: 0px;
    }
  }
  
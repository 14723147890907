.outerMainSinglePop {
  margin-bottom: 50px;
}
.populatBrandsHeading {
  margin: 0%;
  font-size: 28px;
  color: black;
  font-weight: 600;
  margin-bottom: 30px !important;
  margin-top: 30px;
}
.brandInnerTopDic {
  display: flex;
  border: 1px solid rgb(199, 199, 199);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  padding: 10px;
}
.brandInnerTopDic:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: 1px solid orange;
  cursor: pointer;
}
.brandImageInnerDiv {
  border: 1px solid rgb(199, 199, 199);
  border-radius: 5px;
  padding: 1px;
  height: 90px;
}
.brandImageInnerDiv img {
  width: 80px;
  height: auto;
}
.brandContentInnerDiv {
  margin-left: 20px;
  padding-top: 10px;
}
.brandContentInnerH3 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin: 0;
  line-height: 1.2;
  min-height: 40px;
  display: flex;
  align-items: left;
  justify-content: left;
}
.brandContentInnerP {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 700;
}

.hr_span:after {
  content: "";
  width: 60px;
  height: 3px;
  background-color: #ffe1008a;
  display: block;
  margin: 8px 0;
}

/* ÷-=================÷ */

.TopOfferbyCategory {
  margin: 0%;
  font-size: 20px;
  color: black;
  font-weight: 600;
  margin-bottom: 30px !important;
  margin-top: 0px;
}

.offerCardOuterMain {
  border: 1px solid rgb(199, 199, 199);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  padding: 20px;
  margin-bottom: 30px;
}
.topSectionPopOff {
  display: flex;
  padding: 10px;
}
.offerNg {
  background-color: #f3f7fd;
  border-radius: 10px;
  padding: 10px 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  width: 50%;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  color: black;
  height: 100px;
}

.offerNg h6 {
  font-weight: 700;
  color: black;
  font-size: 18px;
  margin: 0%;
}

.LogoofferPopularByCat {
  border-radius: 5px;
  border: 1px solid lightgray;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  height: 99px;
  margin-left: 20px;
  width: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LogoofferPopularByCat img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.OfferTextContentCategory h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  color: black;
  margin-left: 10px;
}

.OfferTextContentCategory p {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 300;
  color: black;
  margin-left: 10px;
}

.OfferPriceContentCategory h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  color: black;
  margin-left: 10px;
}

.OfferPriceContentCategory h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  color: rgb(161, 161, 161);
  margin-left: 10px;
}

.OfferPriceContentCategory h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 300;
  color: black;
  margin-left: 10px;
}

.OfferPriceContentCategoryButtonDiv button {
  background-color: orange;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  height: 40px;
  width: 100%;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 10px;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .brandInnerTopDic {
    margin-bottom: 30px;
  }
}

.popular-brands-container {
  margin-top: 20px;
}
.popular-brands-row {
  display: flex;
  flex-wrap: wrap;
}
.tabs-container {
  display: flex;
  flex-direction: column;
}
.tab-item,
.active-tab-item {
  padding: 10px 0 10px 60px;
  cursor: pointer;
  font-weight: bold;
  text-align: left;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
}

.active-tab-item {
  background-color: #f3f7fd;
  color: #000000;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  margin: 0;
  padding: 0%;
  font-family: 'Lato', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.nav-item{
  padding-left:15px !important ;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .nav-item{
    padding-left:0px !important ;
  }
  .navbar-collapse{
    margin-bottom: 50px !important;
  }
}



.hero-image111111{
  margin-top: 100px !important;
  background-image: linear-gradient(rgba(0, 0, 0, 0.627), rgba(0, 0, 0, 0.793)),
    url("./assets/services/serv.avif") !important;
  height: 350px !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: relative !important;
  display: flex !important;
  align-items: center !important;
}

.hero-text_12 {
  color: white !important;
  text-align: left !important;
  padding: 20px !important;
}

.hero-text_12 h1 {
  font-size: 32px;
  margin-left: 40px;
  margin-top: 20px;
  font-weight: 700;
  color: white !important;
}

.hero-text_12 p {
  margin-top: 20px;
  font-size: 16px;
  margin-left: 40px;
  color: white !important;
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .hero-text_12 h1 {
    font-size: 22px;
    margin-left: 0px;
    color: white !important;
  }

  .hero-image111111 {
    margin-top: 70px;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .hero-text_12 p{
    margin-left: 0px ;
    color: white !important;
  }
}

.hero-text_12 button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 10px 25px;
  color: black;
  background-color: #ddd;
  text-align: center;
  cursor: pointer;
}

.hero-text_12 button:hover {
  background-color: #555;
  color: white;
}
